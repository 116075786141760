<div class="search-box-wrapper">
    <i class="fal fa-search search-icon"></i>
      <div class="w-100" [class.search-box-container]=label [class.filled]="searchTermInput.value">
        @if(label){
          <label class="search-label">{{ label | translate }}</label>
        }

        <input
          #searchTermInput
          [matAutocomplete]="auto"
          [placeholder]="label ? (placeholderText | translate) : (placeholder | translate)"
          class="search-box"
          (focus)="label && onFocus()"
          (blur)="label && onBlur()"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onOptionSelected($event)"
        >
          <mat-option *ngFor="let option of filteredProductNames" [value]="option">
            {{ option }}
          </mat-option>
        </mat-autocomplete>

        <div class="x-mark-icon">
          <i
            class="far fa-times-circle"
            (click)="clearInput()"
            *ngIf="!isSearchTermInputEmpty"
          ></i>
        </div>
      </div>
</div>
